/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { createClient } from 'pexels';
import { PEXELS_API_KEY } from '../../../../../../../redux/slices/application/pexels/pexelsModels';
import { ImageList, ImageListItem, InputAdornment, TextField } from '@mui/material';
import { Props } from '../../../../../../../redux/slices/content/contentModels';

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2';
import { KTSVG } from '../../../../../../../../_metronic/helpers';
import { DraggableImage } from '../../../../EditorComponents';
import { Media } from '../../../../../../../redux/slices/media/mediaModels';
import moment from 'moment';
//----------------------------------------

type Query = {
  query: string,
  srcList: string[],
}

const DEFAULT_SRC_LIST = ["pexels"]
// const DEFAULT_SRC_LIST = ["pexels", "unsplash"]

const schema = yup.object().shape({
  query: yup.string().trim().required().min(1).max(100),    
  srcList: yup.mixed().required() 
})

//----------------------------------------
export const SubTabImage: React.FC<Props> = ({className}) => {
  const client = createClient(PEXELS_API_KEY);

  const [selectedSrcList, setSelectedSrcList] = useState<any[]>(DEFAULT_SRC_LIST);

  const [result, setResult] = useState<any[]>([]);
  const [resultLandscape, setResultLandscape] = useState<any[]>([]);
  const [resultPortrait, setResultPortrait] = useState<any[]>([]);
  
  const [query, setQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  console.log("result : ", result)

  // console.log("loading : ", loading)
  // console.log("nextPage : ", nextPage)

  const { 
    register, 
    setValue,
    handleSubmit,
    trigger,
    reset,
    watch,
    setError,
    clearErrors,
    formState:{
        errors,        
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful
    }
  } = useForm<Query>({
      resolver: yupResolver(schema),
      defaultValues:{
        query: "",
        srcList: DEFAULT_SRC_LIST
      }
  }) 
  
  // ----------------------------------------
  const viewMoreImages = async () => {
    const nextP = nextPage + 1
    setLoading(true)
    setNextPage(nextP)

    try{
      let response: any
      // ------------------------

      if (query.length > 0){
        response = await client.photos.search({ query, page: nextP, per_page: perPage });                
      }else{
        response = await client.photos.curated({ page: nextP, per_page: perPage });        
      }

      if (response){
        let arrKeys = result.map(res => res.id)        
        let mergeResults = result

        //---reduce the redundant
        response.photos.map((ph: any) => {
          if (! arrKeys.includes(ph.id)){
            arrKeys = [...arrKeys, ph.id]
            mergeResults = [...mergeResults, ph]
          }
        })

        if (result.length !== mergeResults.length){
          setResult(mergeResults)
        }else{
          setLoading(false)
        }
      } 
    }catch(error){
      setLoading(false)
      console.log("error", error)
      Swal.fire(
          'There is something wrong during the loading!',
          'error'
      )
    }
  }

  //---------------------------------------------------- 
  const formSubmitHandler: SubmitHandler<Query> = async (inputs ) => {
    const query = inputs.query
    // --------------------

    setLoading(true)
    setNextPage(1)
    setQuery(query)

    try{
      if (query.length > 0){
        const response:any = await client.photos.search({ query, per_page: perPage });
        // console.log("submit respon: ", response)

        if (response){
          setResult(response.photos)
        } 
      }
    }catch(error){
      setLoading(false)
      console.log("error", error)
      Swal.fire(
          'There is something wrong during the search!',
          'error'
      )
    }
  }

  // ---------------------------------
  useEffect(()=>{
    if (result){      
      setResultLandscape(result.filter(res => res.width >= res.height))
      setResultPortrait(result.filter(res => res.width < res.height))      
    }     
    setLoading(false)
  },[result])

  // ---------------------------------
  useEffect(()=>{
    setNextPage(1)
    setLoading(true)
    const response:any = client.photos.curated({per_page: perPage }).then((response: any) => {
      // console.log("response Image: ", response)
      if (response){
        setResult(response.photos)
        setLoading(false)
      } 
    })
  },[])

  //-----------------------------------------
  return (    
      <div 
        className='h-100 w-100 mx-0'
        style={{
          maxHeight:"100%",
          overflowX:"hidden"
          // scrollbarWidth: "thin"
        }}
      >                     
          <form id="frm-edit-query" className='form' onSubmit={handleSubmit(formSubmitHandler)}> 
            <div className="mb-2 sticky-top bg-gray-300">                      
              <TextField 
                {...register("query")}                
                label="Search"
                size='small' 
                autoComplete='off'
                // autoCorrect='on'
                fullWidth 
                margin='dense'
                variant='outlined' 
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setQuery(e.target.value)}
                InputProps={{
                  // disableUnderline: true,
                  type: 'search',
                  endAdornment: (
                    <InputAdornment position="end">
                      <button type='submit' className='btn btn-primary text-hover-white btn-sm px-1'
                        style={{marginRight: "-14px", borderRadius: "0px 3px 3px 0px"}}
                      >
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </button> 
                    </InputAdornment>
                  )
                }}                          
                error={!! errors.query}
                helperText={`${errors.query?.message ?? ''}`}              
              />            
            </div>
            <div className='mb-6 d-flex'>
              {DEFAULT_SRC_LIST.map(((apiSrc, ind) => {
                return (                  
                  <div key={`apiSrc-${ind}`} className='form-check form-switch form-check-custom form-switch-sm form-check-solid'>
                    <input className="form-check-input" type="checkbox" id="flexSwitchApiSrc" value="" 
                      checked={selectedSrcList.includes(apiSrc)}
                      onChange={() => {    
                        if (selectedSrcList.includes(apiSrc)){
                          setSelectedSrcList(selectedSrcList.filter(src => src !== apiSrc))
                        }else{
                          setSelectedSrcList([...selectedSrcList, apiSrc])
                        }                        
                      }}
                  /> 
                  <label className="form-check-label text-gray-700 text-capitalize me-5" htmlFor="flexSwitchApiSrc">
                    {apiSrc}
                  </label>   
              </div> 
                )
              }))}            
            </div>
            
            <div className='mb-2'>              
              <ul className="nav nav-tabs mb-3 fs-6 justify-content-center" style={{borderBottom:"unset"}}>  
                <li className="nav-item">
                  <a  href="#kt_tab_pane_img_landscape" className="nav-link active bg-transparent text-hover-primary text-underline text-active-primary text-muted border-0 px-3" data-bs-toggle="tab">
                    <span className='ms-1 fs-md-8 fs-lg-6'>Landscape</span>
                    <span className='badge badge-circle badge-light ms-2'>{resultLandscape.length}</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a  href="#kt_tab_pane_img_portrait" className="nav-link bg-transparent text-hover-primary text-active-primary text-muted border-0 px-3" data-bs-toggle="tab">
                    <span className='ms-0 fs-md-8 fs-lg-6'>Portrait</span>
                    <span className='badge badge-circle badge-light ms-2'>{resultPortrait.length}</span>
                  </a>
                </li>                
              </ul>
            </div>
          </form>
        <div className="mb-3">
          <div className="tab-content pb-10 mb-5 mb-xl-8 w-100 h-100 ms-1" id="myTabContent">
            {/* Landscape Templates */}
            <div
              className="tab-pane fade show active h-100 w-100 mx-0"
              id="kt_tab_pane_img_landscape"
              role="tabpanel"              
            > 
              
              {resultLandscape && 
                <ImageList variant="masonry" cols={2} gap={8}>
                  {resultLandscape.map((photo: any, ind) => {
                    const item: Media = {
                                          source_id   : photo.id,
                                          source_group_id : "remote-"+ photo.id,
                                          name        : photo.alt,    
                                      
                                          created_at  : moment().local().format("YYYY-MM-DD HH:mm:ss"),
                                          updated_at  : moment().local().format("YYYY-MM-DD HH:mm:ss"),
                                          deleted_at  : null,    
                                          
                                          width       : photo.width,
                                          height      : photo.height,
                                          duration    : 1000,
                                      
                                          is_remote   : 1,
                                          modified_time   : moment().local().format("YYYY-MM-DD HH:mm:ss"),
                                          
                                          rotate_down : "0",
                                          rotate_left : "0",
                                          rotate_right: "0",
                                          save_name   : photo.alt,
                                          size        : 0,
                                          
                                          status      : "1",    //status: 0: not ready, 1: ready to use, 3: deleted 
                                          storage     : "",  
                                          thumb       : "",
                                          type        : "image",    
                                          
                                          url         : photo.src.medium,
                                          url_thumb   : photo.src.small,
                                      
                                          user_id     : "",
                                          approve_by  : 0, 
                                        }
                    return(
                      <ImageListItem key={photo.id}>
                        {/* <img
                          src={`${photo.src.medium}?w=248&fit=crop&auto=format`}
                          srcSet={`${photo.src.medium}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={photo.alt}
                          loading="lazy"
                        /> */}
                        <DraggableImage 
                          key={`${ind}`} 
                          className="rounded"
                          item={item}
                          // inUse={Object.keys(mediaIDInUse).includes(item.source_id)? 1: 0}
                          // deletable = {currentFolder.readonly === 1 ? false : true}
                          // isSelected = {selMediaSrcIds.includes(item.source_id)}
                          // isDraggable = {!deletedSrcIds.includes(item.source_id) ? true: false}
                          isDraggable = {true}
                        />                     
                      </ImageListItem>
                    )
                  }
                )}
                </ImageList>
              }
              {loading && 
                <div className='text-center mt-10'>
                  <span className='indicator-progress text-gray-400' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                    Loading...                  
                  </span>
                </div>                
              }
              <button type="button" className='btn btn-secondary btn-sm'
                onClick={() => viewMoreImages()}
              >
                More...
              </button>
            </div>    
            <div
              className="tab-pane fade h-100 w-100 mx-0"
              id="kt_tab_pane_img_portrait"
              role="tabpanel"              
            >               
              {resultPortrait && 
                <ImageList variant="masonry" cols={2} gap={8}>
                  {resultPortrait.map((photo: any) => (
                    <ImageListItem key={photo.id}>
                      <img
                        src={`${photo.src.medium}?w=248&fit=crop&auto=format`}
                        srcSet={`${photo.src.medium}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={photo.alt}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              }
              {loading && 
                <div className='text-center mt-10'>
                  <span className='indicator-progress text-gray-400' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                    Loading...                  
                  </span>
                </div>                
              }
              <button type="button" className='btn btn-secondary btn-sm'
                onClick={() => viewMoreImages()}
              >
                More...
              </button>
            </div>     
          </div>     
          
        </div>
      </div>
   
  )
}

