import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../../../../_metronic/assets/ts/components'
import { SyntheticEvent, useEffect, useState } from 'react'
import { 
  GalleryBreadcrumb,
  GalleryFiles,
  GalleryMenu
} from '../../../EditorComponents'
import { useGetImagesQuery } from '../../../../../../redux/slices/application/pexels/apiPexelsSlice'
import { createClient } from 'pexels';
import { PEXELS_API_KEY } from '../../../../../../redux/slices/application/pexels/pexelsModels';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { SubTabImage } from './partials/SubTabImage';
import { SubTabVideo } from './partials/SubTabVideo';
import { TabContext, TabPanel } from '@mui/lab';
import { Badge, Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { SubTabAI } from './partials/SubTabAI';

const TabMedia = () => {

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //---------------------------------------------------- 
  const [tabValue, setTabValue] = useState("tab_panel_ai"); 
  const handleChange = (event: SyntheticEvent<Element, Event>, newTabValue: any) => {
      setTabValue(newTabValue); 
  };  

  
  // -------------------------------
  return (
    <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300">
        <div className="d-flex flex-column-auto h-70px bg-gradient-primary" 
          style={{
            paddingTop:"2px", 
            // backgroundImage: "linear-gradient(#F04E5D, #9C258F)"
          }}
        >
          {/* Tab Menu */}
          <div className='w-100 mt-8 ms-0 me-0 pt-0 '>
          <TabContext value={tabValue}> 
            <Box sx={{ borderBottom: 1, borderColor: "#E4E6EF" }}>               
              <Tabs 
                onChange={handleChange}
                value={tabValue}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                selectionFollowsFocus
                style={{padding: "0"}}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >                   
                <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}  label={<span className='me-3'>Image</span>}  value="tab_panel_image" />                   
                <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}  label={<span className='me-3'>Video</span>}  value="tab_panel_video" />                                     
                <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "50px"}}   label={<span className='me-3'>AI</span>}     value="tab_panel_ai" /> 
              </Tabs> 
            </Box> 
            <TabPanel value="tab_panel_ai" className='p-1 py-5'>
              <div className="h-100 w-100 mx-0">
                <div className="container">
                  <div className="row px-0 gy-1">                  
                    <SubTabAI className=''/>
                  </div>              
                </div>
              </div>
            </TabPanel>

            <TabPanel value="tab_panel_image" className='p-1 py-5'>
              <div className="h-100 w-100 mx-0">
                <div className="container">
                  <div className="row px-0 gy-1">                  
                    <SubTabImage className=''/>
                  </div>              
                </div>
              </div>
            </TabPanel>

            <TabPanel value="tab_panel_video" className='p-1 py-5'>
              <div className="h-100 w-100 mx-0">
                <div className="container">
                  <div className="row px-0 gy-1">                  
                    <SubTabVideo className=''/>
                  </div>              
                </div>
              </div>
            </TabPanel>
          </TabContext>

            {/* <ul className="nav nav-tabs mb-5 fs-6" style={{borderBottom:"unset"}}>  
              <li className="nav-item">
                <a  href="#kt_tab_pane_ai" className="nav-link active bg-active-primary border-0 px-3" data-bs-toggle="tab">
                <i className='bi bi-magic fs-5 text-light-primary' /> 
                  <span className='text-light ms-1 fs-md-8 fs-lg-6'>AI</span>
                </a>
              </li>
              <li className="nav-item">
                <a  href="#kt_tab_pane_image" className="nav-link bg-active-primary border-0 px-3" data-bs-toggle="tab">
                  <KTSVG path='/media/icons/duotune/general/gen006.svg' className='svg-icon-2 text-light-primary' />
                  <span className='text-light ms-2 fs-md-8 fs-lg-6'>Image</span>
                </a>
              </li>
              <li className="nav-item">              
                <a  href="#kt_tab_pane_video" className="nav-link bg-active-primary bg-hover-primary border-0 px-3"  data-bs-toggle="tab">
                  <i className='bi bi-camera-video-fill fs-4 text-light-primary' />                  
                  <span className='text-light ms-2 fs-md-8 fs-lg-6'>Video</span>
                </a>
              </li>                
            </ul> */}
          </div>
        </div>
        
        {/* <div className="d-flex flex-column-fluid flex-center pt-7"
            style={{maxHeight:"90%", overflowY:"auto"}}
        >
          <div className="tab-content pb-10 mb-5 mb-xl-8 w-100 h-100 ms-1" id="myTabContent">
            <div
              className="tab-pane fade show active h-100 w-100 mx-0"
              id="kt_tab_pane_ai"
              role="tabpanel"
            >
              <div className="container">
                <div className="row px-0 gy-1">                  
                  <SubTabAI className=''/>
                </div>              
              </div>
            </div>

            <div
              className="tab-pane fade h-100 w-100 mx-0"
              id="kt_tab_pane_image"
              role="tabpanel"
            >
              <div className="container">
                <div className="row px-0 gy-1">                  
                  <SubTabImage className=''/>
                </div>              
              </div>
            </div>

            <div  
              className="tab-pane fade" 
              id="kt_tab_pane_video"
              role="tabpanel"              
            >
              <div className="container">
                <div className="row px-0 gy-1">
                  <SubTabVideo className=''/>
                </div>              
              </div>
            </div>             
          </div>
        </div> */}
    </div>    
    // <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300">
    //     <div className="d-flex flex-column-auto h-70px bg-gradient-primary pt-5">
    //       <GalleryMenu className=''/>
    //     </div>
    //     <div className="d-flex flex-column-auto bg-light">
    //       <GalleryBreadcrumb className=''/>
    //     </div>
    //     <div className="d-flex flex-column-fluid flex-center pt-5"
    //         style={{maxHeight:"90%", overflowY:"auto"}}
    //     >
    //       {/* <GalleryFiles className='card-xxl-stretch mb-5 mb-xl-8 h-100'/> */}
    //       <GalleryFiles className='card-xxl-stretch mb-5 mb-xl-8 h-100'/>
    //     </div>
    // </div>
    // <div>
    //   <form onSubmit={handleSearch}>
    //     <input name="query" type="text" placeholder="Search for photos" />
    //     <button type="submit">Search</button>
    //   </form>
    //   {/* {isLoading && <p>Loading...</p>} */}
    //   {/* {error && <p>Error: {error.message}</p>} */}
    //   {data && (
    //     <div>
    //       {data.map((photo: any) => (
    //         <img key={photo.id} src={photo.src.medium} alt={photo.alt} />
    //       ))}
    //     </div>
    //   )}
    // </div>
  )
}

export {TabMedia}
