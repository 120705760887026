import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { DeviceLog } from "./reportModels";

const API_URL : string | undefined  = process.env.REACT_APP_API3_URL

export const reportApi3 = createApi({
    reducerPath: "reportApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),

    refetchOnMountOrArgChange: 30,

    endpoints: (builder) => ({
            
        getReportDevice : builder.query<[], DeviceLog>({
            query: ({deviceId, type}) => `report/${deviceId}/${type}`,
            keepUnusedDataFor: 5,              
        }),

    })
})

export const {
    useGetReportDeviceQuery
} = reportApi3