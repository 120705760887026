import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./resellerModels";

const initialState: InitialState = {
}

const resellerSlice = createSlice({
    name: 'reseller',
    initialState,
    reducers:{
    }
})

export default resellerSlice.reducer
export const {
    
} = resellerSlice.actions