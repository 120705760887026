import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Device, iDeviceAudit, iDeviceOfflineNotification, iDeviceReportNotification, Other, PushNotification } from './deviceModels';
import { Group } from '../group/groupModels';
import { getAuthModel } from '../../auth'

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
// const API_URL : string | undefined  = process.env.REACT_APP_API_URL_DEV

interface ListResponse<T> {
    page: number
    per_page: number
    total: number
    total_pages: number
    data: T[],
}
export  const auth = getAuthModel()   
export const devicesApi = createApi({
    reducerPath: "devicesApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    
    tagTypes: ['Group', 'Device', 'AccountLicensePackage'],

    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllDevices : builder.query<Device[], number>({
            query: () => "devices",
            providesTags: ['Group','Device'],
            keepUnusedDataFor: 5,   // configuration for an individual endpoint, overriding the api setting            
        }),    

        getUnassignedDevice : builder.query<Device[], number>({
            query: () => "devices/unassigned",
            providesTags: ['Group','Device'],
            keepUnusedDataFor: 5,
        }),
                
        getDevice : builder.query<Device[], string>({
             query: (device) => `devices/?name=${device}`,
             providesTags: ['Device']
        }),
        getFilterDevices : builder.query<Device[], string>({
            query: (filter) => `devices/${filter}`,
            providesTags: ['Device']
        }),

        getDeviceByStatus : builder.query<Device[], number>({
            query: (status_id) => `devices/status/${status_id}`,
            providesTags: ['Device']
       }),

        listDevices: builder.query<Device[], number | void>({
            query: (page = 1, limit = 2) => `devices?_page=${page}&_limit=${limit}`,
            providesTags: ['Device']
        }),
        // listDevices: builder.query<ListResponse<Device>, number | void>({
        //     query: (page = 1, limit = 2) => `devices?_page=${page}&_limit=${limit}`,
        //     providesTags: ['Device']
        //     // providesTags: (result, error, page) =>
        //     // result
        //     // ? [
        //     //     // Provides a tag for each post in the current page,
        //     //     // as well as the 'PARTIAL-LIST' tag.
        //     //     ...result.data.map(({ id }) => ({ type: 'Device' as const, id })),
        //     //         { type: 'Device', id: 'PARTIAL-LIST' }, ]
        //     // : [{ type: 'Device', id: 'PARTIAL-LIST' }],
        // }),
        
        // ----------------------------------------------------
        resetDevicePin : builder.mutation<Object, string>({
            query: (id) => ({
                url: `devices/${id}/resetPin`,
                method: "GET",                
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',                    
                },
            }),            
            invalidatesTags: ['Group','Device']
        }),
        
        addDevice: builder.mutation<string, Object>({
            query(data){
                return{
                    url: "devices",
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: data
                }
            },            
            invalidatesTags: ['Group','Device','AccountLicensePackage']
            
        }),

        addNewDevice: builder.mutation<Object, Device>({
            query(dev){
                return{
                    url: "device/add",
                    method: "POST",
                    body: dev
                }
            },            
            invalidatesTags: ['Group','Device','AccountLicensePackage']            
        }),
        // addNewDevice: builder.mutation<Object, Other>({
        //     query(pin){
        //         return{
        //             url: "device/add",
        //             method: "POST",
        //             body: pin
        //         }
        //     },            
        //     invalidatesTags: ['Group','Device']            
        // }),

        linkDevice: builder.mutation<Object, Other>({
            query: ({deviceId, ...rest}) => ({
                url: `device/link/${deviceId}`,
                method: "PATCH",
                body: rest
            }),
            
            invalidatesTags: ['Group','Device','AccountLicensePackage']
            
        }),

        // assignLicense: builder.mutation<Object, Other>({
        //     query: ({deviceId, licensePackageId}) => ({
        //         url: `device/${deviceId}/license/${licensePackageId}`,
        //         method: "PUT", 
        //     }),            
        //     invalidatesTags: ['Group','Device']            
        // }),

        assignLicense: builder.mutation<Object, Device>({
            query: (dev) => ({
                url: `device/${dev.id}/license/${dev.licensePackageId}`,
                method: "PUT", 
            }),            
            invalidatesTags: ['Group','Device','AccountLicensePackage']            
        }),

        updateDevice: builder.mutation<Object, Device>({
            query: ({id, ...rest}) => ({
                url: `devices/${id}`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Group','Device','AccountLicensePackage']
        }),

        updateDeviceOfflineNotification: builder.mutation<Object, iDeviceOfflineNotification>({
            query: ({deviceId, ...rest}) => ({
                url: `device/${deviceId}/notification/offline`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Device']
        }),

        updateDeviceReportNotification: builder.mutation<Object, iDeviceReportNotification>({
            query: ({deviceId, ...rest}) => ({
                url: `device/${deviceId}/notification/report`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Device']
        }),

        updateDeviceAudit: builder.mutation<Object, iDeviceAudit>({
            query: ({deviceId, ...rest}) => ({
                url: `device/${deviceId}/audit`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Device']
        }),

        deleteDevice: builder.mutation<void, string>({
            query: (id) => ({
                url: `device/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Group','Device','AccountLicensePackage']
        }),

        pushToDevice: builder.mutation<any, PushNotification>({
            query: ({deviceId, event, ...rest}) => ({
                url: `device/${deviceId}/push/${event}`,
                method: "POST",
                body: rest
            })
        }),
        
    })
})

export const { 
    useGetAllDevicesQuery, 
    useGetUnassignedDeviceQuery,
    useGetDeviceQuery, 
    useGetDeviceByStatusQuery,
    useGetFilterDevicesQuery,
    useListDevicesQuery,
    useResetDevicePinMutation,
    useAddDeviceMutation,
    useAddNewDeviceMutation,
    useLinkDeviceMutation,
    useAssignLicenseMutation,
    useUpdateDeviceMutation,
    useDeleteDeviceMutation,  
    usePushToDeviceMutation,
    useUpdateDeviceOfflineNotificationMutation,
    useUpdateDeviceReportNotificationMutation,
    useUpdateDeviceAuditMutation
} = devicesApi